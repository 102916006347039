<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-12">
						<h5 class="p0 mb0">
							<strong>Registro de cotización</strong>
						</h5>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<hr class="hrText" data-content="♦" />
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<insertOrUpdateImpressment />
					</div>
				</div>
			</div>
		</div>
	</a-spin>
</template>
<script>
//
import { mapGetters } from 'vuex'
import insertOrUpdateImpressment from '@/components/impressment'

export default {
	name: 'insertOrUpdateImpressmentView',
	components: {
		insertOrUpdateImpressment,
	},
	computed: {
		...mapGetters('impressment', ['spinnerLoader', 'spinnerLoaderLabel']),
	},
}
</script>